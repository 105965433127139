<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="obhlidkovyFormular-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Obhlídkový formulář k zakázce č. {{ contract.kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }" ref="providerObhlidkovy">
          <b-form @submit.stop.prevent="handleSubmit(saveForm)">
            <div class="d-flex fs-15px justify-content-between">
              <div class="d-flex pr-2">
                <div class="text-nowrap">
                  <label for="cityInstallation" class="pr-1 bold">Město (obec) instalace</label>
                  <label class="req">*</label>
                </div>
                <ValidationProvider rules="required|min:3" name="'Město (obec) instalace'" v-slot="{ classes, errors }" class="">
                  <b-form-input
                      id="cityInstallation"
                      name="cityInstallation"
                      v-model.trim="contract.cityInstallation"
                      class="text-capitalize-first-letter form-control form-control-sm w-170px"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
                <div class="pl-2 w-210px text-nowrap pt-label color-grey mnogotochie">
                  <b-tooltip target="okresInfo" variant="secondary">{{ contract.okres }}</b-tooltip>
                  okres: <span id="okresInfo">{{ contract.okres }}</span>
                </div>
              </div>
              <div class="d-flex">
                <span class="text-nowrap pr-2 bold pt-label">Datum obhlídky</span>
                <b-form-datepicker
                    id="obhlidkyDate-datepicker"
                    v-model="contract.obhlidkyDate"
                    :start-weekday="1"
                    size="sm"
                    locale="cs"
                    class="w-250px"
                />
              </div>
            </div>

            <div class="d-flex pt-2">
              <div class="d-flex pr-3 pt-4px">
                <a href="https://nahlizenidokn.cuzk.cz/VyberBudovu/Stavba/InformaceO" target="_blank" rel="noopener noreferrer">
                  <img class="uzk-logo" src="@/assets/nahlizenidokn_cuzk_cz.jpg" alt="Nahlížení do katastru nemovitostí" />
                </a>
              </div>

              <div class="d-flex pt-1_5">
                <div class="text-nowrap">
                  <label for="odkazNaKatastr" class="pr-2 pt-label">Odkaz na katastr</label>
                </div>
                <ValidationProvider :rules="{ regex: /^https:\/\/nahlizenidokn\.cuzk\.cz\/.*/ }" name="'Odkaz na katastr'" v-slot="{ classes, errors }">
                  <b-form-input
                      id="odkazNaKatastr"
                      type="text"
                      size="sm"
                      v-model.trim="contract.odkazNaKatastr"
                      class="w-300px"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
                <b-button @click="openOdkazNaKatastrLink" variant="outline-secondary" class="link-odkaz-btn"
                  :disabled="!isCorrectLink"
                >
                  <b-icon-link class="fs-25px" />
                </b-button>

                <a href="#"
                   class="bold pl-3 pt-label text-nowrap"
                   :class="[isCorrectLink ? 'custom-link' : 'link-disabled']"
                   @click="getInfoFromKatastrPage"
                >
                  Nahrát data z katastru
                </a>
              </div>
            </div>

            <div class="d-flex pt-3">
              <div class="d-flex">
                <div class="text-nowrap">
                  <label for="addressInstallation" class="pr-2 bold pt-label">Ulice</label>
                </div>
                <b-form-input
                    id="addressInstallation"
                    type="text"
                    size="sm"
                    v-model.trim="contract.addressInstallation"
                    class="form-control form-control-sm w-290px"
                />
              </div>
              <div class="d-flex">
                <label for="cislaDomu" class="pt-label pl-3 pr-0">č.p.</label>
                <b-form-input
                    id="cislaDomu"
                    type="text"
                    size="sm"
                    v-model.trim="contract.cislaDomu"
                    class="w-90px"
                />

                <label for="cisloOrientacni" class="pt-label pl-3 pr-0">č.o.</label>
                <b-form-input
                    id="cisloOrientacni"
                    type="text"
                    size="sm"
                    v-model.trim="contract.cisloOrientacni"
                    class="w-90px"
                />

                <label for="evidencniCisloDomu" class="pt-label pl-3 pr-0">(č.ev.</label>
                <b-form-input
                    id="evidencniCisloDomu"
                    type="text"
                    size="sm"
                    v-model.trim="contract.evidencniCisloDomu"
                    class="w-90px"
                />
                <label class="pt-label pr-0">)</label>

                <span class="">,</span>
                <div class="text-nowrap pl-3">
                  <label for="zip" class="pr-1">PSČ</label>
                  <label class="req">*</label>
                </div>
                <ValidationProvider rules="required|min:6|max:6" name="'PSČ'" v-slot="{ classes, errors }" class="w-50">
                  <b-form-input
                      id="zip"
                      type="text"
                      size="sm"
                      v-model.trim="contract.zip"
                      v-mask="'### ##'"
                      :title="errors[0]"
                      :state="getState(classes)"
                      @input="changeZIP"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="d-flex pt-3 justify-content-between">
              <div class="pl-47px">
                <b-button @click="openGoogleMapsLink" variant="outline-primary" class="text-nowrap mapy-btn" size="sm">
                  <img class="mapy-logo" src="@/assets/google_maps.png" alt="Google maps" />
                  Google maps
                </b-button>
                <b-button @click="openMapyLink" variant="outline-success" class="text-nowrap ml-3 mapy-btn" size="sm">
                  <img class="mapy-logo" src="@/assets/mapy_cz.png" alt="Mapy.cz" />
                  Mapy.cz
                </b-button>
              </div>
              <div>
                <b-button @click="openPostaLink" variant="outline-warning" class="text-nowrap ml-3 postaonline-btn"
                          size="sm" title="Vyhledávání PSČ"
                >
                  <img class="postaonline-logo" src="@/assets/postaonline_cz.png" alt="Postaonline.cz" />
                  PoštaOnline
                </b-button>
              </div>
            </div>

            <div class="d-flex pt-3">
              <div class="d-flex w-50 pr-3">
                <div class="text-nowrap">
                  <label for="cadastralTerritory" class="pr-1">Katastrální území</label>
                  <label class="req">*</label>
                </div>
                <ValidationProvider rules="required" name="'Katastrální území'" v-slot="{ classes, errors }" class="w-100">
                  <b-form-input
                      id="cadastralTerritory"
                      type="text"
                      size="sm"
                      v-model.trim="contract.cadastralTerritory"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
              </div>

              <div class="d-flex w-50">
                <div class="text-nowrap">
                  <label for="standsOnProperty" class="pr-1">Stojí na pozemku</label>
                  <label class="req">*</label>
                </div>
                <span class="pt-label">p.č.</span>
                <ValidationProvider rules="required" name="'Stojí na pozemku'" v-slot="{ classes, errors }">
                  <b-form-input
                      id="standsOnProperty"
                      type="text"
                      size="sm"
                      v-model.trim="contract.standsOnProperty"
                      class="w-120px"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>

                <span class="pr-2 pt-label text-nowrap pl-4">Číslo LV</span>
                <b-form-input
                    id="numberLV"
                    type="text"
                    size="sm"
                    v-model.trim="contract.numberLV"
                />
              </div>
            </div>

            <div class="d-flex pt-3 fs-15px">
              <div>
                <label class="text-nowrap bold">Způsoby ochrany nemovitosti:</label>
                <div>
                  <b-form-checkbox
                      id="opt0"
                      v-model="contract.territoryProtectionInfo.opt0"
                      name="opt0"
                      @change="changeOpt0"
                      class="text-nowrap"
                  >
                    {{ UDAJE_O_OCHRANE_UZEMI_LIST.OPT0 }}
                  </b-form-checkbox>
                </div>
              </div>
              <div class="pl-1 d-flex">
                <div>
                  <b-form-checkbox
                      id="opt1"
                      v-model="contract.territoryProtectionInfo.opt1"
                      name="opt1"
                      class="text-nowrap"
                      :disabled="isDisableProtectionInfo"
                  >
                    {{ UDAJE_O_OCHRANE_UZEMI_LIST.OPT1 }}
                  </b-form-checkbox>
                  <b-form-checkbox
                      id="opt4"
                      v-model="contract.territoryProtectionInfo.opt4"
                      name="opt4"
                      class="text-nowrap"
                      :disabled="isDisableProtectionInfo"
                  >
                    {{ UDAJE_O_OCHRANE_UZEMI_LIST.OPT4 }}
                  </b-form-checkbox>
                </div>

                <div class="pl-3">
                  <b-form-checkbox
                      id="opt2"
                      v-model="contract.territoryProtectionInfo.opt2"
                      name="opt2"
                      class="text-nowrap"
                      :disabled="isDisableProtectionInfo"
                  >
                    {{ UDAJE_O_OCHRANE_UZEMI_LIST.OPT2 }}
                  </b-form-checkbox>
                  <b-form-checkbox
                      id="opt5"
                      v-model="contract.territoryProtectionInfo.opt5"
                      name="opt5"
                      class="text-nowrap"
                      :disabled="isDisableProtectionInfo"
                  >
                    {{ UDAJE_O_OCHRANE_UZEMI_LIST.OPT5 }}
                  </b-form-checkbox>
                </div>

                <div class="pl-3">
                  <b-form-checkbox
                      id="opt3"
                      v-model="contract.territoryProtectionInfo.opt3"
                      name="opt3"
                      class="text-nowrap"
                      :disabled="isDisableProtectionInfo"
                  >
                    {{ UDAJE_O_OCHRANE_UZEMI_LIST.OPT3 }}
                  </b-form-checkbox>
                  <b-form-checkbox
                      id="opt6"
                      v-model="contract.territoryProtectionInfo.opt6"
                      name="opt6"
                      class="text-nowrap"
                      :disabled="isDisableProtectionInfo"
                  >
                    {{ UDAJE_O_OCHRANE_UZEMI_LIST.OPT6 }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <hr class="mt-4 mb-4">

            <div class="d-flex">
              <span class="pr-2 text-nowrap pt-label bold fs-15px">Roční spotřeba el. energie (MWh)</span>
              <ValidationProvider rules="required|double|min_value:0" name="'Počet osob'" v-slot="{ classes, errors }">
                <vue-numeric
                    id="rocniSpotebaMWh"
                    decimal-separator=","
                    :precision="1"
                    class="form-control form-control-sm w-100px"
                    :class="getClassByValidatorState(classes)"
                    v-model="contract.rocniSpotebaMWh"
                    :title="errors[0]"
                />
              </ValidationProvider>

              <span class="pl-4 pr-2 pt-label fs-15px text-nowrap">Počet osob v domácnosti</span>
              <b-form-select
                  id="numPersons"
                  v-model="contract.numPersons"
                  :options="lists.numPersons"
                  class="form-select form-select-sm w-70px br-4"
                  size="sm"
              />

              <div class="text-nowrap pl-4">
                <b-form-checkbox
                    id="kopieVyuctovani"
                    v-model="contract.kopieVyuctovani"
                    class="pl-4 fs-15px"
                >
                  kopie vyúčtování
                </b-form-checkbox>
              </div>
            </div>

            <div class="pt-3">
              <div class="pb-2 fs-15px">
                <span>Stručný popis odběru elektrické energie během dne, případně roku:</span>
              </div>
              <b-form-input
                  id="numberLV"
                  type="text"
                  size="sm"
                  v-model.trim="contract.strucnyPopisOdbEnergie"
              />
            </div>

            <div class="d-flex pt-3 fs-15px">
              <div class="pr-4 d-flex">
                <span class="text-nowrap pr-2 pt-label">Zdroj vytápění</span>
                <b-form-select
                    v-model="contract.zdrojVytapeni"
                    :options="lists.zdroj"
                    class="form-select form-select-sm w-170px"
                    size="sm"
                />
              </div>
              <div class="pr-4 d-flex">
                <span class="text-nowrap pr-2 pt-label">Zdroj ohřevu TUV</span>
                <b-form-select
                    v-model="contract.zdrojOhrevuTUV"
                    :options="lists.zdroj"
                    class="form-select form-select-sm w-170px"
                    size="sm"
                />
              </div>
              <div class="d-flex w-100">
                <span class="text-nowrap pr-2 pt-label">Akumulace přebytků do TUV</span>
                <b-form-select
                    v-model="contract.akumulacePrebytkuDoTUV"
                    :options="lists.ano_ne"
                    class="form-select form-select-sm"
                    size="sm"
                    @change="changeAkumulacePrebytkuDoTUV"
                />
              </div>
            </div>

            <div class="d-flex pt-3 fs-15px">
              <div class="pr-4 d-flex">
                <span class="text-nowrap pr-2 pt-label">Dotační program</span>
                <b-form-select
                    v-model="contract.dotacniProgram"
                    :options="lists.dotacniProgram"
                    class="form-select form-select-sm w-250px"
                    size="sm"
                >
                  <template #first>
                    <b-form-select-option :value="null" />
                  </template>
                </b-form-select>
              </div>
              <div class="d-flex w-100" v-if="contract.akumulacePrebytkuDoTUV === 'ano'">
                <span class="text-nowrap pr-2 pt-label">Typ bojleru a spirály</span>
                <b-form-input
                    id="numberLV"
                    type="text"
                    size="sm"
                    v-model.trim="contract.typBojleru"
                />
              </div>
            </div>

            <hr class="mt-4 mb-4">

            <div class="d-flex fs-15px">
              <div class="pr-3">
                <span class="text-nowrap pr-2">Umístění ER</span>
                <b-form-select
                    v-model="contract.umisteniER"
                    :options="lists.umisteniER"
                    class="form-select form-select-sm w-170px"
                    size="sm"
                />
              </div>
              <div class="pr-3">
                <span class="text-nowrap pr-2">Počet fází</span>
                <b-form-select
                    v-model="contract.numPhases"
                    :options="lists.numPhases"
                    class="form-select form-select-sm w-60px"
                    size="sm"
                />
              </div>
              <div class="pr-3">
                <span class="text-nowrap pr-2">Hlavní jístič</span>
                <b-form-select
                    v-model="contract.circuitBreaker"
                    :options="lists.circuitBreakers"
                    class="form-select form-select-sm w-75px"
                    size="sm"
                />
              </div>
              <div class="pr-3">
                <span class="text-nowrap pr-2">Distributor</span>
                <b-form-select
                    v-model="contract.distributor"
                    :options="lists.distributors"
                    class="form-select form-select-sm w-70px"
                    size="sm"
                    @change="changeDistributor"
                />
              </div>
              <div>
                <ValidationProvider rules="max:50" name="'Text'" v-slot="{ classes, errors }">
                  <b-form-input
                      v-if="contract.distributor === 'LDS'"
                      id="ldsDistributorComment"
                      type="text"
                      size="sm"
                      v-model.trim="contract.ldsDistributorComment"
                      class="w-170px"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="d-flex mt-4 w-100 justify-content-between">
              <div class="pt-2 w-25">
                <a href="#" class="color-green" @click="vlozitSouboryFoto">
                  Zažádat o projekt v PV*SOL
                </a>
              </div>
              <div class="w-50>">
                <b-button type="submit" class="" variant="primary">Uložit data a zavřít</b-button>
              </div>
              <div class="pt-2 w-25 d-flex justify-content-end">
                <a href="#" @click="generateXlsxOF">
                  OF (částečně vyplněný)
                  <b-icon-download class="pl-1 pt-1" variant="info" font-scale="1.5" />
                </a>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>


<script>
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import saveAs from "save-as";
import moment from "moment";
import {MIME_TYPES, UDAJE_O_OCHRANE_UZEMI_LIST} from "@/constants/form.constants";

export default {
  props: {
    id: {type: String},
    show: { type: Boolean },
    firstName: { type: String },
    lastName: { type: String },
  },
  mixins: [commonMixin],
  computed: {
    UDAJE_O_OCHRANE_UZEMI_LIST() {
      return UDAJE_O_OCHRANE_UZEMI_LIST
    },
    isHaveAccess() {
      return this.$store.getters.isObhlidkovyFormularModalAllowed;
    },
    isCorrectLink() {
      const queryLink = this.contract.odkazNaKatastr || '';
      return queryLink.match(/^https:\/\/nahlizenidokn\.cuzk\.cz\/.*/);
    },
    isDisableProtectionInfo() {
      return this.contract.territoryProtectionInfo.opt0;
    },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      lists: {
        numPersons: Array(10).fill(0).map((e,i) => i+1),
        numPhases: [],
        circuitBreakers: [],
        distributors: [],
        umisteniER: [],
        zdroj: [],
        ano_ne: [],
        dotacniProgram: [],
      },
      contract: {
        kodZakazky: '',
        addressInstallation: null,
        cadastralTerritory: null,
        standsOnProperty: null,
        numberLV: null,
        CHKO: false,
        monumentCare: false,
        extensiveProtectedArea: false,
        kopieVyuctovani: false,
        numPersons: null,
        numPhases: null,
        circuitBreaker: null,
        distributor: null,
        obhlidkyDate: null,
        zip: null,
        okres: null,
        cislaDomu: null,
        cisloOrientacni: null,
        evidencniCisloDomu: null,
        umisteniER: null,
        rocniSpotebaMWh: 0,
        strucnyPopisOdbEnergie: null,
        zdrojVytapeni: null,
        zdrojOhrevuTUV: null,
        akumulacePrebytkuDoTUV: null,
        dotacniProgram: null,
        typBojleru: null,
        cityInstallation: null,
        territoryProtectionInfo: {
          opt0: false,
          opt1: false,
          opt2: false,
          opt3: false,
          opt4: false,
          opt5: false,
          opt6: false,
        },
        odkazNaKatastr: null,
        ldsDistributorComment: null,
      },
    }
  },
  methods: {
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer([
          'NUMBER_PHASES', 'CIRCUIT_BREAKERS_1', 'DISTRIBUTORS', 'UMISTENI_ER', 'ZDROJ', 'ANO_NE', 'DOTACNI_PROGRAM'
        ]) || {};
        this.lists.numPhases = dataConst.NUMBER_PHASES;
        this.lists.circuitBreakers = dataConst.CIRCUIT_BREAKERS_1;
        this.lists.distributors = dataConst.DISTRIBUTORS;
        this.lists.umisteniER = dataConst.UMISTENI_ER;
        this.lists.zdroj = dataConst.ZDROJ;
        this.lists.ano_ne = dataConst.ANO_NE;
        this.lists.dotacniProgram = dataConst.DOTACNI_PROGRAM;
      } catch (e) {
        console.error('getInitData', e.response?.data);
      }
    },
    async saveForm(isNotClose = false) {
      try {
        const url = `/obchod/saveObhlidkovyFormular`;
        const {data} = await axios.post(url, { data: this.contract });
        this.$snotify[data.status](data.data.message);
        if (data.status === 'success') {
          this.$emit('form-saved')
          if (!isNotClose) this.$emit('close-modal');
        }
      } catch (e) {
        console.error('saveInspectionForm', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getSavedData() {
      try {
        const url = `/obchod/getObhlidkovyFormularInfo`;
        const {data: {data}} = await axios.get(url, {params: {id: this.id}});
        const contractInfo = data?.contractInfo || {};
        this.contract = {...this.contract, ...contractInfo};
        this.contract.rocniSpotebaMWh = this.contract.rocniSpotebaMWh || 0;
        if (!this.contract.okres && this.contract.zip) {
          await this.getOkresByPSC();
        }
      } catch (e) {
        console.error('getSavedData', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getOkresByPSC() {
      try {
        const url = `/obchod/getOkresByPSC`;
        const psc = this.contract.zip.replace(' ', '');
        const {data: {data}} = await axios.get(url, {params: {psc}});
        this.contract.okres = data?.okres || '';
      } catch (e) {
        console.error('getOkresByPSC', e.response?.data);
      }
    },
    getClassByValidatorState({invalid, validated}) {
      return validated && !invalid ? '' : 'form-control is-invalid';  // 'form-control is-valid' - for green
    },
    changeAkumulacePrebytkuDoTUV() {
      this.contract.typBojleru = '';
    },
    getOfFileName() {
      const date = this.contract.obhlidkyDate ? moment(this.contract.obhlidkyDate).format('YYYY-MM-DD') : null;
      const userName = [this.lastName, this.firstName].filter(e => e);
      return [this.contract.kodZakazky, ...userName, 'OF', date].filter(e => e).join('_') + '.xlsx';
    },
    async generateXlsxOF() {
      try {
        await this.saveForm(true);
        const fileName = this.getOfFileName();

        const url = `/generate/xlsx/OF`;
        const {data} = await axios({
          method: "get",
          url: url,
          params: {id: this.id},
          responseType: "blob",
        });
        const blob = new Blob([data], { type: `${ MIME_TYPES.xlsx };base64,` });
        this.$snotify['success']('OF vygenerováno');
        saveAs(blob, fileName);
      } catch (e) {
        console.error('generateXlsxOF', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async vlozitSouboryFoto() {
      const valid = await this.$refs.providerObhlidkovy.validate();
      if (!valid) return;
      await this.saveForm(true);
      this.$emit('show-vlozit-soubory-foto-modal');
    },
    openMapyLink() {
      const address = this.getAddress();
      const addressFormatted = address.replaceAll(' ', '%20').replace(',', '%2C');
      const addressFinal = `https://en.mapy.cz/zakladni?q=${addressFormatted}&source=addr`;
      window.open(addressFinal, '_blank', 'noopener,noreferrer');
    },
    openPostaLink() {
      const zip = this.contract?.zip?.replace(' ', '');
      const mestoInstalace = this.contract?.cityInstallation;
      let searchString = zip ? `${zip}/` : '';
      if (!zip && mestoInstalace) {
        searchString = `?search=${mestoInstalace}&send=Odeslat&do=searchForm-submit`;
      }
      const url = `https://www.psc.cz/${searchString}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    },
    openGoogleMapsLink() {
      const address = this.getAddress();
      const addressFormatted = address.replaceAll(' ', '+');
      const addressFinal = `https://www.google.com/maps/place/${addressFormatted}`;
      window.open(addressFinal, '_blank', 'noopener,noreferrer');
    },
    openOdkazNaKatastrLink() {
      if (!this.isCorrectLink) { return }

      window.open(this.contract.odkazNaKatastr, '_blank', 'noopener,noreferrer');
    },
    getAddress() {
      const street = this.contract?.addressInstallation ? `${this.contract?.addressInstallation} ` : '';
      const house = this.contract?.cislaDomu ? `${this.contract.cislaDomu},` : '';
      const zip = this.contract?.zip ? ` ${this.contract.zip} ` : ' ';
      const city = this.contract.cityInstallation ? `${this.contract.cityInstallation}` : '';
      return street + house + zip + city;
    },
    async getInfoFromKatastrPage() {
      try {
        if (!this.isCorrectLink) {
          return;
        }

        const url = `/obchod/getInfoFromKatastrPage`;
        const {data: {data, code}} = await axios.post(url, {queryLink: this.contract.odkazNaKatastr});

        if (code === 200) {
          this.contract.cadastralTerritory = data.cadastralTerritory;
          this.contract.numberLV = data.numberLV;
          this.contract.standsOnProperty = data.standsOnProperty;
          this.contract.cityInstallation = data.cityInstallation || this.contract.cityInstallation;
          if (!this.contract.addressInstallation && !this.contract.cislaDomu) {
            this.contract.addressInstallation = data.adresniMista;
          }
          const opts = data.territoryProtectionInfoOpt || [];
          opts.forEach( opt => {
            if (opt === 'opt0') {
              this.changeOpt0(true);
            } else {
              this.contract.territoryProtectionInfo.opt0 = false;
            }
            this.contract.territoryProtectionInfo[opt] = true;
          });
          this.$snotify['success']('Údaje z katastru byly úspěšně přijaty');
        } else {
          this.$snotify['error'](data?.message || 'Chyba při přístupu do katastru');
        }
      } catch (e) {
        this.$snotify['error'](e.response?.data?.message || 'Chyba při přístupu do katastru');
      }
    },
    async changeZIP() {
      const psc = this.contract.zip.replace(' ', '');
      if (psc.length < 5) return this.contract.okres = null;

      await this.getOkresByPSC();
    },
    changeDistributor() {
      if (this.contract.distributor !== 'LDS') {
        this.contract.ldsDistributorComment = '';
      }
    },
    changeOpt0(resetNot0 = false) {
      if (this.contract.territoryProtectionInfo.opt0 || resetNot0) {
        this.contract.territoryProtectionInfo.opt1 = false;
        this.contract.territoryProtectionInfo.opt2 = false;
        this.contract.territoryProtectionInfo.opt3 = false;
        this.contract.territoryProtectionInfo.opt4 = false;
        this.contract.territoryProtectionInfo.opt5 = false;
        this.contract.territoryProtectionInfo.opt6 = false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getConstants();
    await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },

}
</script>

<style scoped>
  label {
    padding-right: 10px;
    font-size: 15px;
  }
  p {
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .req {
    color: darkred;
    font-size: 17px
  }
  .uzk-logo {
    width: 280px;
    border: 1px solid lightgrey;
  }
  .mapy-logo {
    height: 20px;
    cursor: pointer;
  }
  .postaonline-logo {
    height: 20px;
    cursor: pointer;
  }

  /* don't delete below */
  .postaonline-btn.btn-outline-warning, .mapy-btn.btn-outline-success {
    color: #2c3e50;
  }
  .mapy-btn.btn-outline-success:hover {
    color: white;
  }
  .link-odkaz-btn.btn-outline-secondary {
    border-color: #ced4da;
  }
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.15rem 0.5rem
  }
  .btn {
    line-height: 1;
    height: 29px;
  }
  .link-odkaz-btn {
    width: 30px;
    padding: 0;
    border-radius: 0;
  }
</style>
